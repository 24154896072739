import { ActionType, createAction, props } from "@ngrx/store";
import {
  CombinedTransactions,
  ReallocateTransactionRequest,
  RestoreTransactionRequest,
  SwapSignageTransactionRequest,
  TransactionDetails,
  TransactionStatementPeriodDetails,
} from "./transaction.types";

export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS = "GET_TRANSACTION_STATEMENT_PERIOD_DETAILS";
export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS = "GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS";
export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE = "GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE";

export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_TRANSACTION_DETAILS_SUCCESS = "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_FAILURE = "GET_TRANSACTION_DETAILS_FAILURE";

export const SAVE_VALIDATE_STATEMENT_TRANSACTION = "SAVE_VALIDATE_STATEMENT_TRANSACTION";
export const SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS = "SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS";
export const SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE = "SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE";
export const CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION = "CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION";

export const REVALIDATE_SUMMARY = "REVALIDATE_SUMMARY";
export const REVALIDATE_SUMMARY_SUCCESS = "REVALIDATE_SUMMARY_SUCCESS";
export const REVALIDATE_SUMMARY_FAILURE = "REVALIDATE_SUMMARY_FAILURE";

export const PROCESS_TRANSACTION_FLAG = "PROCESS_TRANSACTION_FLAG";
export const PROCESS_TRANSACTION_FLAG_SUCCESS = "PROCESS_TRANSACTION_FLAG_SUCCESS";
export const PROCESS_TRANSACTION_FLAG_FAILURE = "PROCESS_TRANSACTION_FLAG_FAILURE";

export const PROCESS_CATEGORIES = "PROCESS_CATEGORIES";
export const PROCESS_CATEGORIES_SUCCESS = "PROCESS_CATEGORIES_SUCCESS";
export const PROCESS_CATEGORIES_FAILURE = "PROCESS_CATEGORIES_FAILURE";

export const RESET_TRANSACTIONSFLAG_INFO = "RESET_TRANSACTIONSFLAG_INFO";
export const RESET_TRANSACTIONSCATEGORY_INFO = "RESET_TRANSACTIONSCATEGORY_INFO";

export const REALLOCATE_TRANSACTION = "REALLOCATE_TRANSACTION";
export const REALLOCATE_TRANSACTION_SUCCESS = "REALLOCATE_TRANSACTION_SUCCESS";
export const REALLOCATE_TRANSACTION_FAILURE = "REALLOCATE_TRANSACTION_FAILURE";

export const SWAP_SIGNAGE_TRANSACTION = "SWAP_SIGNAGE_TRANSACTION";
export const SWAP_SIGNAGE_TRANSACTION_SUCCESS = "SWAP_SIGNAGE_TRANSACTION_SUCCESS";
export const SWAP_SIGNAGE_TRANSACTION_FAILURE = "SWAP_SIGNAGE_TRANSACTION_FAILURE";

export const RESTORE_TRANSACTION = "RESTORE_TRANSACTION";
export const RESTORE_TRANSACTION_SUCCESS = "RESTORE_TRANSACTION_SUCCESS";
export const RESTORE_TRANSACTION_FAILURE = "RESTORE_TRANSACTION_FAILURE";

export const ResetTransactionsFlagInfo = createAction(RESET_TRANSACTIONSFLAG_INFO);
export const ResetTransactionsCategoryInfo = createAction(RESET_TRANSACTIONSCATEGORY_INFO);

export const GetTransactionStatementPeriodDetails = createAction(
  GET_TRANSACTION_STATEMENT_PERIOD_DETAILS,
  props<{ loanId: string }>()
);

export const GetTransactionStatementPeriodDetailsSuccess = createAction(
  GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS,
  props<{ data: TransactionStatementPeriodDetails[] }>()
);

export const GetTransactionStatementPeriodDetailsFailure = createAction(
  GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const GetTransactionDetails = createAction(
  GET_TRANSACTION_DETAILS,
  props<{ loanId: string; summaryId: string }>()
);

export const GetTransactionDetailsSuccess = createAction(
  GET_TRANSACTION_DETAILS_SUCCESS,
  props<{ data: TransactionDetails[]; deletedTransactions: TransactionDetails[] }>()
);

export const GetTransactionDetailsFailure = createAction(
  GET_TRANSACTION_DETAILS_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const SaveValidateStatementTransaction = createAction(
  SAVE_VALIDATE_STATEMENT_TRANSACTION,
  props<{ data: TransactionDetails[]; isValidated: boolean; loanId: string; summaryId: string }>()
);

export const SaveValidateStatementTransactionSuccess = createAction(
  SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS,
  props<{ data: CombinedTransactions }>()
);

export const SaveValidateStatementTransactionFailure = createAction(
  SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const ClearSaveValidateStatementTransaction = createAction(CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION);

export const ReValidateSummary = createAction(
  REVALIDATE_SUMMARY,
  props<{ statementId: string; isValidate: boolean }>()
);

export const ReValidateSummarySuccess = createAction(REVALIDATE_SUMMARY_SUCCESS, props<{ message: string }>());

export const ReValidateSummaryFailure = createAction(
  REVALIDATE_SUMMARY_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const ProcessTransactionFlag = createAction(PROCESS_TRANSACTION_FLAG, props<{ loanId: string }>());

export const ProcessTransactionFlagSuccess = createAction(
  PROCESS_TRANSACTION_FLAG_SUCCESS,
  props<{ message: string }>()
);

export const ProcessTransactionFlagFailure = createAction(
  PROCESS_TRANSACTION_FLAG_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const ProcessCategories = createAction(PROCESS_CATEGORIES, props<{ loanId: string }>());

export const ProcessCategoriesSuccess = createAction(PROCESS_CATEGORIES_SUCCESS, props<{ message: string }>());

export const ProcessCategoriesFailure = createAction(
  PROCESS_CATEGORIES_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const ReallocateTransaction = createAction(
  REALLOCATE_TRANSACTION,
  props<{ data: ReallocateTransactionRequest }>()
);

export const ReallocateTransactionSuccess = createAction(
  REALLOCATE_TRANSACTION_SUCCESS,
  props<{ data: CombinedTransactions }>()
);

export const ReallocateTransactionFailure = createAction(
  REALLOCATE_TRANSACTION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const SwapnSignageTransaction = createAction(
  SWAP_SIGNAGE_TRANSACTION,
  props<{ data: SwapSignageTransactionRequest }>()
);

export const SwapnSignageTransactionSuccess = createAction(
  SWAP_SIGNAGE_TRANSACTION_SUCCESS,
  props<{ data: CombinedTransactions }>()
);

export const SwapnSignageTransactionFailure = createAction(
  SWAP_SIGNAGE_TRANSACTION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export const RestoreTransaction = createAction(RESTORE_TRANSACTION, props<{ data: RestoreTransactionRequest }>());

export const RestoreTransactionSuccess = createAction(
  RESTORE_TRANSACTION_SUCCESS,
  props<{ data: CombinedTransactions }>()
);

export const RestoreTransactionFailure = createAction(
  RESTORE_TRANSACTION_FAILURE,
  props<{ errors?: string[]; message: string }>()
);

export type TransactionActions =
  | ActionType<typeof GetTransactionStatementPeriodDetails>
  | ActionType<typeof GetTransactionStatementPeriodDetailsSuccess>
  | ActionType<typeof GetTransactionStatementPeriodDetailsFailure>
  | ActionType<typeof GetTransactionDetails>
  | ActionType<typeof GetTransactionDetailsSuccess>
  | ActionType<typeof GetTransactionDetailsFailure>
  | ActionType<typeof SaveValidateStatementTransactionSuccess>
  | ActionType<typeof SaveValidateStatementTransactionFailure>
  | ActionType<typeof ClearSaveValidateStatementTransaction>
  | ActionType<typeof ReValidateSummary>
  | ActionType<typeof ReValidateSummarySuccess>
  | ActionType<typeof ReValidateSummaryFailure>
  | ActionType<typeof ProcessTransactionFlag>
  | ActionType<typeof ProcessTransactionFlagSuccess>
  | ActionType<typeof ProcessTransactionFlagFailure>
  | ActionType<typeof ProcessCategories>
  | ActionType<typeof ProcessCategoriesSuccess>
  | ActionType<typeof ProcessCategoriesFailure>
  | ActionType<typeof ResetTransactionsFlagInfo>
  | ActionType<typeof ResetTransactionsCategoryInfo>
  | ActionType<typeof ReallocateTransaction>
  | ActionType<typeof ReallocateTransactionSuccess>
  | ActionType<typeof ReallocateTransactionFailure>
  | ActionType<typeof SwapnSignageTransaction>
  | ActionType<typeof SwapnSignageTransactionSuccess>
  | ActionType<typeof SwapnSignageTransactionFailure>
  | ActionType<typeof RestoreTransaction>
  | ActionType<typeof RestoreTransactionSuccess>
  | ActionType<typeof RestoreTransactionFailure>;
